import BackgroundImage from '../../../assets/bg-zap-new.jpg';

import styled from 'styled-components';

interface ContainerProps {
    isSmall: boolean;
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.palette.common.white}};

    .left-container {
        background: url(${BackgroundImage});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100vh;
        width: 40%;

        .logo {
            margin: 40px 0 0 40px;
            cursor: pointer;
        }
    }

    .right-container {
        width: ${({ isSmall }) => (isSmall ? '60' : '100')}%;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const TextContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 71px 60px;
`;
